<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Account Information
        </h3>
        <!-- <span class="text-muted font-weight-bold font-size-sm mt-1" >Update your personal informaiton</span> -->
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Save Changes
        </button>
        <!-- <button type="reset" class="btn btn-secondary" @click="reset()">
          Reset
        </button> -->
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Avatar</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="image-input image-input-outline" id="kt_profile_avatar">
              <div
                class="image-input-wrapper symbol-label"
                :style="{ backgroundImage: `url(${photo})` }"
              ></div>
              <label
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="change"
                data-toggle="tooltip"
                title=""
                data-original-title="Change avatar"
              >
                <i class="fa fa-pen icon-sm text-muted"></i>
                <input
                  type="file"
                  name="profile_avatar"
                  accept=".png, .jpg, .jpeg"
                  @change="onFileChange($event)"
                />
                <input type="hidden" name="profile_avatar_remove" />
              </label>
              <span
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="cancel"
                data-toggle="tooltip"
                title="Cancel avatar"
              >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
              <span
                class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                data-action="remove"
                data-toggle="tooltip"
                title="Remove avatar"
                @click="deleteAvatar()"
              >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
            </div>
            <span class="form-text text-muted"
              >Allowed file types: png, jpg, jpeg.</span
            >
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Name</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              v-model="name_input"
              class="form-control form-control-lg form-control-solid"
              type="text"
            />
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Username</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              v-model="user_name_input"
              class="form-control form-control-lg form-control-solid"
              type="text"
            />
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Contact Phone</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-phone"></i>
                </span>
              </div>
              <input
                v-model="phone_input"
                type="number"
                class="form-control form-control-lg form-control-solid"
                placeholder="Phone"
              />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Email Address</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <input
                v-model="email_input"
                type="email"
                class="form-control form-control-lg form-control-solid"
                placeholder="Email"
                autocomplete="off"
              />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Password</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fas fa-lock"></i>
                </span>
              </div>
              <input
                v-model="password_input"
                type="password"
                class="form-control form-control-lg form-control-solid"
                placeholder="Enter password"
                autocomplete="off"
              />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Confirm Password</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fas fa-lock"></i>
                </span>
              </div>
              <input
                v-model="confirm_password_input"
                type="password"
                class="form-control form-control-lg form-control-solid"
                placeholder="Enter password"
              />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-xl-3 col-lg-3 col-form-label text-right font-weight-bold"
            >Kỳ thi phụ trách</label
          >
          <div class="col-lg-9 col-xl-6">
            <v-select
                placeholder="Chọn kỳ thi cho nhân viên"
                v-model="product_category_ids"
                :items="product_category_tags"
                item-value="id"
                item-text="name.vi"
                solo multiple chips
            ></v-select>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import image_compress from "@/mixins/image_compress.js";
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "UserInformation",
  mixins: [image_compress],
  props: ["data_user"],
  data() {
    return {
      avatar_data: null,
      avatar_file: null,
      email_input: "",
      phone_input: "",
      user_name_input: "",
      name_input: "",
      password_input: "",
      confirm_password_input: "",
      product_category_tags: [],
      product_category_ids: [],
    };
  },
  computed: {
    default_photo: {
      get() {
        return window.location.origin + "/media/users/blank.png";
      },
    },
    photo() {
      return this.avatar_data == null ? this.default_photo : this.avatar_data;
    },
  },
  watch: {
    name_input: function (val) {
      this.$emit("setDataForm", { filed: "name", value: val });
    },
    email_input: function (val) {
      this.$emit("setDataForm", { filed: "email", value: val });
    },
    phone_input: function (val) {
      this.$emit("setDataForm", { filed: "phone", value: val });
    },
    user_name_input: function (val) {
      this.$emit("setDataForm", { filed: "user_name", value: val });
    },
    password_input: function (val) {
      this.$emit("setDataForm", { filed: "password", value: val });
    },
    confirm_password_input: function (val) {
      this.$emit("setDataForm", { filed: "confirm_password", value: val });
    },
    avatar_data: function (val) {
      this.$emit("setDataForm", { filed: "avatar_data", value: val });
    },
    avatar_file: function (val) {
      this.$emit("setDataForm", { filed: "avatar_file", value: val });
    },
    product_category_ids: function (val) {
      this.$emit("setDataForm", { filed: "product_category_ids", value: val });
    },
    data_user: function (val) {
      if (val !== undefined) {
        this.setInputData();
      }
    },
  },
  created() {
    this.getAllProductCategory()
    if (this.data_user) {
      this.setInputData();
    }
  },
  methods: {
    setInputData() {
      this.name_input = this.data_user.name;
      this.email_input = this.data_user.email;
      this.phone_input = this.data_user.phone;
      this.user_name_input = this.data_user.user_name;
      this.avatar_data = this.data_user.avatar;
      this.password_input = "";
      this.confirm_password_input = "";
      this.product_category_ids = this.data_user.product_categories.map((e) => {
        return e.id;
      });
    },
    save() {
      this.$emit("submitForm");
    },
    deleteAvatar() {
      this.avatar_data = null;
      this.avatar_file = null;
    },
    onFileChange(e) {
      let file = e.target.files[0];
      if (file === undefined) {
        let empty = {
          blob: null,
          base64: null,
          file: null,
        };
        this.done(empty);
        return;
      }
      this.handleFile(file);
    },
    done(obj) {
      this.avatar_file = obj.file;
      this.avatar_data = obj.blob;
    },
    async getAllProductCategory() {
      let vm = this;
      await ApiService.get("prep-app/product-category/limit").then(function (response) {
        if ([200].includes( response.status)) {
          vm.product_category_tags = response.data;
        }
      });
    },
  },
};
</script>
